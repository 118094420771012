import { useEffect } from "react";

import {
  CpqCore,
  CpqQueryClientProvider,
  OverridableComponentsKeys,
  StandardComponents,
} from "@4pace-cpq/core";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { QueryClientProvider } from "@tanstack/react-query";

import { cpqPath, queryClient } from "./App";
import AdditionalQuoteFields from "./components/AdditionalQuoteFields/AdditionalQuoteFields";
import { ShoppingCartButton } from "./components/ShoppingBasket/ShoppingCartButton";
import { CloneItemIconButtonWrapper } from "./cpq/overrides/CloneItemIconButtonWrapper/CloneItemIconButtonWrapper";
import { CustomIndicatorConfigurableProductInLineItem } from "./cpq/overrides/CustomIndicatorConfigurableProductInLineItem/CustomIndicatorConfigurableProductInLineItem";
import { CustomItemGroupActionButtonsArea } from "./cpq/overrides/CustomItemGroupActionButtonsArea";
import { DeleteItemIconButtonWrapper } from "./cpq/overrides/DeleteRowMenuItemWrapper/DeleteRowMenuItemWrapper";
import { LineItemAccordion } from "./cpq/overrides/LineItemAccordion/LineItemAccordion";
import { MinimalApp } from "./renderer/MinimalApp";
import { ConfigurationFinishedContextProvider } from "./renderer/controls/ConfigurationFinishedPage/ConfigurationFinishedContextProvider";
import { LoadingSpinner } from "./tools/LoadingSpinner";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const cfgSessionId = urlParams.get("cfgSessionId");
const userSessionId = urlParams.get("userSessionId");
export const language = urlParams.get("language");
export const localeID = urlParams.get("localeID");
export const iframeId = urlParams.get("iframeId");

const isInIframe = window.location !== window.parent.location;

const isDevEnvironment =
  window.location.href.includes("stag") ||
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost");

export function Router(): JSX.Element {
  const isInCPQ = window.location.pathname.includes(cpqPath);

  useEffect(() => {
    if (cfgSessionId) {
      window.sessionStorage.setItem("cfgSessionId", cfgSessionId);
    }
    if (userSessionId) {
      window.sessionStorage.setItem("userSessionId", userSessionId);
    }
  });

  return (
    <>
      {!isInCPQ && (
        <>
          {!isInIframe && (
            <Button href={cpqPath + "/opportunities/"}>Go to CPQ</Button>
          )}
          <QueryClientProvider client={queryClient}>
            <ConfigurationFinishedContextProvider>
              {!isInIframe && <ShoppingCartButton />}
              <MinimalApp />
            </ConfigurationFinishedContextProvider>
          </QueryClientProvider>
        </>
      )}

      {isInCPQ && (
        <>
          {!isInIframe && (
            <Button sx={{ marginLeft: "2rem" }} href="/">
              Go Back
            </Button>
          )}
          <CpqQueryClientProvider>
            <CpqCore
              showDebugTools={isDevEnvironment}
              basePath={cpqPath}
              languageCode={language ? language : "de-DE"}
              componentsOverride={{
                [OverridableComponentsKeys.AbsoluteProgressIndicator]:
                  LoadingSpinner,
                [OverridableComponentsKeys.CartViewFooter]:
                  CustomItemGroupActionButtonsArea,
                [OverridableComponentsKeys.IndicatorConfigurableProductInLineItem]:
                  CustomIndicatorConfigurableProductInLineItem,
                [OverridableComponentsKeys.CloneItemIconButton]:
                  CloneItemIconButtonWrapper,
                [OverridableComponentsKeys.DeleteItemIconButton]:
                  DeleteItemIconButtonWrapper,
                [OverridableComponentsKeys.LineItemAccordion]:
                  LineItemAccordion,
                [OverridableComponentsKeys.PageHeading]: ({ text }) => (
                  <StandardComponents.PageHeading text={text} />
                ),
                [OverridableComponentsKeys.CartViewPageSubHeading]:
                  AdditionalQuoteFields,
                [OverridableComponentsKeys.CartIdRouteComponent]: () => (
                  <Box sx={{ minHeight: "1100px" }}>
                    <StandardComponents.CartIdRouteComponent />
                  </Box>
                ),
              }}
            />
          </CpqQueryClientProvider>
        </>
      )}
    </>
  );
}
