import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";

import { RegioluxPrinterIcon } from "@/icons/RegioluxIcons";
import { useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData } from "@/openapi/api-cpq/queries/queries";
import { exportQuoteToExcel } from "@/tools/helper";

export function DownloadShoppingCartButton({
  quoteId,
  isEmpty = true,
}: {
  quoteId: string;
  isEmpty?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { data: extendedData } =
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
      { quoteId },
    );

  return (
    <>
      <Button
        disabled={isEmpty}
        startIcon={
          isLoading ? (
            <CircularProgress color="inherit" size="1rem" />
          ) : (
            <RegioluxPrinterIcon />
          )
        }
        onClick={async () => {
          setIsLoading(true);
          await exportQuoteToExcel(quoteId, {
            ...extendedData,
          });
          setIsLoading(false);
        }}
      >
        {t("regiolux:button.downloadShoppingCart.label")}
      </Button>
    </>
  );
}
